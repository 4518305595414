import React, { useState, useEffect, useCallback } from "react";
import cx from "classnames";
import styles from "./Accounts.module.scss";
import { useTranslation } from "react-i18next";
import { Status } from "../../../../data/types";
import { useMutation } from "@tanstack/react-query";
import { useLinkId } from "../../../../hooks/useLinkId";
import { useAccess } from "../../../../hooks/useAccess";
import { dataBank, RoaringAccount } from "../../../../data/dataBank";
import { Dynamic } from "../../../../components/animate/Dynamic";
import { GenericError } from "../../../../components/Errors/GenericError";
import { Button } from "../../../../components/interactions/Buttons/Button";

interface Props {
  setStatus: React.Dispatch<React.SetStateAction<Status>>;
  setIban: (iban: string) => void;
  iban?: string;
}

export const Accounts: React.FunctionComponent<Props> = ({
  setStatus,
  iban,
  setIban,
}) => {
  const { t } = useTranslation();
  const linkId = useLinkId();
  const access = useAccess();
  const [accounts, setAccounts] = useState<RoaringAccount[]>([]);
  const [bank, setBank] = useState<string>("");

  const {
    mutate: listAccounts,
    isLoading,
    isError,
    reset,
  } = useMutation({
    mutationFn: () => {
      return dataBank(access).getAccounts(linkId);
    },
    onSuccess: (data) => {
      if (!data.completed) {
        setStatus(Status.ERROR);
        return;
      }

      setBank(data.bank);
      data.accounts.sort((a, b) => {
        if (a.valid && b.valid) {
          return a.accountName.localeCompare(b.accountName);
        }

        if (a.valid) {
          return -1;
        }

        if (b.valid) {
          return 1;
        }

        return a.accountName.localeCompare(b.accountName);
      });

      setAccounts(data.accounts);
      if (data.accounts.length === 1 && data.accounts[0].valid) {
        setIban(data.accounts[0].iban);
      }
    },
  });

  useEffect(() => {
    listAccounts();
  }, [listAccounts]);

  const onChange = useCallback((iban: string) => setIban(iban), [setIban]);

  return (
    <div className={cx("m-top-40", styles.accounts)}>
      {accounts.length > 0 ? <h5>{bank}</h5> : null}
      <ul>
        {accounts.map((account) => {
          return (
            <li
              key={account.iban}
              className={cx(styles.account, {
                [styles.active]: account.iban === iban,
              })}
            >
              <button
                className="text-regular"
                disabled={!account.valid}
                onClick={(ev) => {
                  ev.preventDefault();
                  onChange(account.iban);
                }}
              >
                <div
                  className={cx(styles.tickbox, {
                    [styles.selected]: account.iban === iban,
                  })}
                />
                <div className={styles.body}>
                  <div className={styles.name}>
                    {account.accountName || account.iban}
                  </div>
                  <div className={cx(styles.owner, "text-passive")}>
                    {account.accountOwner}
                  </div>
                </div>
              </button>
            </li>
          );
        })}
      </ul>
      <Dynamic name={isError ? "failed" : "loaded"}>
        {isError ? (
          <GenericError>
            {t("We couldn't fetch the accounts. Try again?")}

            <div className="m-top-20">
              <Button
                block
                ghost
                onClick={() => {
                  reset();
                  listAccounts();
                }}
              >
                {t("Retry?")}
              </Button>
            </div>
          </GenericError>
        ) : null}
      </Dynamic>

      <div className={cx("m-top-10", "center")}>
        <Button
          className="text-regular"
          status={isLoading ? Status.PENDING : Status.DEFAULT}
          text
          onClick={() => setStatus(Status.DEFAULT)}
        >
          {t("Change bank")}
        </Button>
      </div>
    </div>
  );
};
