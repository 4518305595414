import React, { useState, useEffect, useCallback, useRef } from "react";
import styles from "./ListBanks.module.scss";
import { useTranslation } from "react-i18next";
import { BankItem, BankType, dataBank } from "../../../../data/dataBank";
import { useAccess } from "../../../../hooks/useAccess";
import { useLinkId } from "../../../../hooks/useLinkId";
import { useMutation } from "@tanstack/react-query";
import {
  Country,
  LegalEntityType,
} from "../../../../data/models/ContractTypes";
import { Contract } from "../../../../data/dataMerchant";
import { RadioGroup } from "../../../../components/form/RadioGroup";
import { TextInput } from "../../../../components/form/TextInput";
import { CardList } from "../../../../components/cards/CardList";
import { Dynamic } from "../../../../components/animate/Dynamic";
import { GenericError } from "../../../../components/Errors/GenericError";
import { Button } from "../../../../components/interactions/Buttons/Button";

interface Props {
  setOwnerType: React.Dispatch<React.SetStateAction<BankType | undefined>>;
  ownerType?: BankType;
  contract: Contract;
}

const prioBanks: Record<Country, string[]> = {
  [Country.SWEDEN]: [
    "Danske Bank",
    "Handelsbanken",
    "Länsförsäkringar Bank",
    "Nordea",
    "Nordea Corporate",
    "SEB",
    "Swedbank",
  ],
  [Country.DENMARK]: [
    "Arbejdernes Landsbank",
    "Danske Bank",
    "Jyske Bank",
    "Nykredit Bank",
    "Nordea",
    "Ringkjøbing Landbobank",
    "Saxo Bank",
    "Spar Nord Bank",
    "Sydbank",
  ],
  [Country.NORWAY]: [],
  [Country.FINLAND]: [
    "Nordea",
    "Nordea Corporate",
    "Danske Bank",
    "Handelsbanken",
    "Aktia",
    "S-Pankki",
    "POP Pankki",
  ],
  [Country.GB]: [],
};

function sortByName(banks: BankItem[]): BankItem[] {
  banks.sort((a, b) => a.name.localeCompare(b.name));
  return banks;
}

function sortByPrio(banks: BankItem[], country: Country): BankItem[] {
  const prioritizedBanks = prioBanks[country];

  banks.sort((a, b) => {
    const firstIsPrio = prioritizedBanks.includes(a.name);
    const secondIsPrio = prioritizedBanks.includes(b.name);

    if (firstIsPrio && secondIsPrio) {
      return 0;
    }

    if (firstIsPrio) {
      return -1;
    }

    if (secondIsPrio) {
      return 1;
    }

    return 0;
  });
  return banks;
}

export const ListBanks: React.FunctionComponent<Props> = ({
  ownerType,
  setOwnerType,
  contract,
}) => {
  return (
    <div>
      <OwnerTypeInput
        ownerType={ownerType}
        setOwnerType={setOwnerType}
        contract={contract}
      />

      <Inner ownerType={ownerType} contract={contract} />
    </div>
  );
};

const OwnerTypeInput: React.FunctionComponent<{
  ownerType?: BankType;
  setOwnerType: React.Dispatch<React.SetStateAction<BankType | undefined>>;
  contract: Contract;
}> = ({ ownerType, setOwnerType, contract }) => {
  const { t } = useTranslation();

  if (
    contract.contractData.legalEntityType !== LegalEntityType.SOLE_PROPRIETARY
  ) {
    return null;
  }

  return (
    <div>
      <RadioGroup
        label={t("Who is the owner of the bankaccount")}
        onChange={(value) => {
          setOwnerType(value);
        }}
        value={ownerType}
        alternatives={[
          {
            value: BankType.PERSONAL,
            text: t(BankType.PERSONAL),
          },
          {
            value: BankType.BUSINESS,
            text: t(BankType.BUSINESS),
          },
        ]}
      />
    </div>
  );
};

const Inner: React.FunctionComponent<{
  ownerType?: BankType;
  contract: Contract;
}> = ({ contract, ownerType }) => {
  const linkId = useLinkId();
  const access = useAccess();
  const [banks, setBanks] = useState<BankItem[]>([]);
  const [filteredBanks, setFilteredBanks] = useState<BankItem[]>([]);
  const [search, setSearch] = useState<string>("");
  const { t } = useTranslation();
  const timer = useRef<number>();
  const [selectedBank, setSelectedBank] = useState<string>();

  const {
    mutate: initLogin,
    isError: isInitError,
    reset: resetInit,
  } = useMutation({
    mutationFn: (bankName: string) => {
      const returnUrl = window.location.href.split("?")[0];

      if (!ownerType) {
        return Promise.reject();
      }

      return dataBank(access).initRoaring(
        linkId,
        bankName,
        returnUrl,
        ownerType,
        contract.contractData.country
      );
    },
    onSuccess: (data) => {
      window.location.href = data.url;
    },
  });

  const onSelect = useCallback(
    (bankItem: BankItem) => {
      resetInit();
      setSelectedBank(bankItem.name);
      initLogin(bankItem.name);
    },
    [initLogin, resetInit]
  );

  const filterFunction = (text: string, items: BankItem[]) => {
    if (!text) {
      setFilteredBanks(items);
      return;
    }

    const searchValue = text.toLocaleLowerCase();

    setFilteredBanks(
      items.filter(
        (bankItem) =>
          bankItem.name.toLocaleLowerCase().indexOf(searchValue) > -1
      )
    );
  };

  const {
    mutate: getBanks,
    isLoading: listBanksPending,
    isError,
    reset,
  } = useMutation(
    (owner: BankType) =>
      dataBank(access).getBanks(linkId, contract.contractData.country, owner),
    {
      onSuccess: (data) => {
        let sortedBanks = sortByName(data);
        sortedBanks = sortByPrio(sortedBanks, contract.contractData.country);
        setBanks(sortedBanks);
        filterFunction(search, sortedBanks);
      },
    }
  );

  useEffect(() => {
    if (!ownerType) {
      return;
    }

    getBanks(ownerType);
  }, [getBanks, ownerType]);

  return (
    <div className={styles.bank}>
      {ownerType ? (
        <>
          <div className={styles.filter}>
            {contract.contractData.legalEntityType ===
            LegalEntityType.SOLE_PROPRIETARY ? (
              <div className={styles.divider} />
            ) : null}
            <TextInput
              label={t("Search bank")}
              disabled={isError || listBanksPending}
              onChange={(value) => {
                setSearch(value);
                window.clearTimeout(timer.current);

                timer.current = window.setTimeout(() => {
                  filterFunction(value, banks);
                }, 300);
              }}
              value={search}
            />
          </div>

          <CardList className="small">
            {filteredBanks.map((bankItem) => {
              return (
                <CardList.CardListItem key={bankItem.name}>
                  <Bank
                    onSelect={onSelect}
                    bank={bankItem}
                    isInitError={isInitError && selectedBank === bankItem.name}
                  />
                </CardList.CardListItem>
              );
            })}

            {banks.length && filteredBanks.length === 0 ? (
              <CardList.CardListItem>
                {t('No match for search value "{{search}}"', {
                  search,
                })}
              </CardList.CardListItem>
            ) : null}
          </CardList>
        </>
      ) : null}

      <Dynamic name={isError ? "isError" : ""}>
        {isError ? (
          <GenericError>
            {t("We couldn't list the applicable banks.")}
            <div className="m-top-20">
              <Button
                ghost
                block
                onClick={() => {
                  if (!ownerType) {
                    return;
                  }
                  reset();
                  getBanks(ownerType);
                }}
              >
                {t("Try again?")}
              </Button>
            </div>
          </GenericError>
        ) : null}
      </Dynamic>
    </div>
  );
};

interface BankProps {
  onSelect: (bank: BankItem) => void;
  bank: BankItem;
  isInitError?: boolean;
}

export const Bank: React.FunctionComponent<BankProps> = ({
  onSelect,
  bank,
  isInitError = false,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.bankItem}>
        <div className={styles.logo}>
          <img src={bank.logo} alt={bank.name} />
        </div>
        <div className={styles.nameAndButton}>
          <div className={styles.bankName}>{bank.name}</div>
          <div className={styles.button}>
            <Button size="small" ghost onClick={() => onSelect(bank)}>
              {t("Login")}
            </Button>
          </div>
        </div>
      </div>
      <Dynamic name={isInitError ? "isInitError" : ""}>
        {isInitError ? (
          <div className="pt-1">
            <GenericError>
              {t("We couldn't initiate the login. Try again?")}
            </GenericError>
          </div>
        ) : null}
      </Dynamic>
    </>
  );
};
